.story
{
    position: relative;
    /* min-height: 100vh; */
    background: #000;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
}
.imageframe
{
    position: relative;
    width: 100%;
    height: 100%;
}
.imageframe img
{
    width: 100%;
    height: auto;
}
.description
{
    color: #fff;
}
.description .title h2
{
    font-family: 'Slackey';
    font-weight: 400;
}
.description .title h1
{
    font-family: 'Slackey';
    font-weight: 900;
    font-size: 3em;
}
.description .title h1 span
{
    font-family: 'Slackey';
    background: linear-gradient(to top, #0066FF 50%, transparent 50%);
}
.description .title
{
    margin-bottom: 50px;
}
.description p
{
    font-weight: 400;

}
@media (max-width: 767px)
{
    .description .title h1
    {
        font-size: 2.2em;
    }
    .description p
    {
        font-size: 1.2em;
    }
}
@media (max-width: 415px)
{
    .description .title h1
    {
        font-size: 2em;
    }
    .description p
    {
        font-size: 1em;
    }
}