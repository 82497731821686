.sdescription
{
    position: relative;
    min-height: 100vh;
    background: #18325a;
    justify-content: center;
    align-items: center;
}
.sdescription .row
{
    justify-content: center;
    align-items: center;
}
.sdescription img
{
    width: 100%;
    height: auto;
}
.sdescription .panel
{
    padding: 20px;
    border-radius: 43px;
    background-color: rgba(20, 21, 26, 0.7);
}
.alignhelper
{
    padding: 30px 45px 10px;
}
.sdescription .panel .alignhelper h1
{
    font-family: 'Slackey';
    text-transform: uppercase;
    font-size: 2.8em;
    font-weight: 900;
    color: #fff;
    margin-bottom: 30px;
}
.sdescription p
{
    color: #fff;
    font-size: 2em;
    font-weight: 700;
}
.sdescription p span
{
    background-color: #0066FF;
}
.sdescription .contract
{
    background-color: #0066FF;
    padding: 25px 30px 15px;
    border-radius: 20px;
    border: 2px solid rgba(255, 255, 255, 0.64);
    box-shadow: 0 0 0px 5px #0066FF;
}
.contract img
{
    height: 1.2em;
    width: auto;
}
.contract h5
{
    font-size: 1.2em;
    color: rgba(255, 255, 255, 0.65);
    font-weight: 400;
}
.contract a
{
    text-decoration: none;
}
.contract p
{
    font-size: 1em;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
}
.sdescription .panel .logo
{
    margin-top: 30px;
    /* margin-bottom: -20px; */
}
.sdescription .panel .logo img
{
    height: 1.2em;
    width: auto;
    opacity: 0.64;
}
@media (max-width: 992px)
{
    .sdescription .panel .alignhelper h1
    {
        font-size: 1.5em;
        margin-bottom: 30px;
    }
    .sdescription p
    {
        font-size: 1em;
    }
    .contract h5
    {
        font-size: 1em;
    }
}
@media (max-width: 767px)
{
    .sdescription .panel .alignhelper h1
    {
        font-size: 2em;
        margin-bottom: 30px;
    }
    .sdescription p
    {
        font-size: 1.3em;
    }
}
@media (max-width: 415px)
{
    .sdescription .panel .alignhelper h1
    {
        font-size: 1.3em;
    }
    .sdescription p
    {
        font-size: 0.7em;
    }
    .contract h5
    {
        font-size: 0.7em;
    }
}