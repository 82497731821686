.buyfish
{
    position: relative;
    width: 100%;
    height: 140vh;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background: linear-gradient(#000000, #18325a);
}
.buyfish img
{
    position: absolute;
    transform: translateX(0px);
    width: auto;
    height: auto;
}
#gallery1
{
    width: auto;
    /* transform: translate(0px, 30px); */
    animation: g-slider 70000ms linear infinite;
}
#gallery2
{
    width: auto;
    /* transform: translate(0px, 30px); */
    animation: g-slider2 70000ms linear infinite;
}
.buyfish .files
{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.files img
{
    position: absolute;
    transform: translateX(0px);
    width: auto;
    height: auto;
}
.files .episode
{
    position: relative;
    transform: translateX(120px) translateY(-340px);
}
.episode img
{
    position: absolute;
}
.episode h1
{
    color: #fff;
    font-family: 'Slackey';
    font-weight: 200;
    transform: translateX(67px) translateY(23px);
    text-shadow: 4px 4px #000;
}
.progress
{
    position: absolute;
    /* height: 32px; */
    width: 257px;
    transform: translate(-0px, 195px);
    z-index: 50;
    border-radius: 16px;
}
.progress .progress-bar
{
    height: 32px;
    border-radius: 16px;
    border: 3.5px solid #424242;
}
.mintcounter
{
    position: absolute;
    top: 0%;
    left: 0%; 
    /* transform: translate(0, 0); */
    width: 100%;
}
.mintcounter h2
{
    font-size: 1.5em;
    font-weight: 700;
    color: #fff;
    z-index: 100;    
    transform: translate(0px, 7px);
}
.minted h3
{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-35px, 215px);
    font-size: 1em;
    text-transform: uppercase;
    font-weight: 700;
    color:rgba(0, 0, 0, 0.6);
    z-index: 100;
}
.buyfish .buybtn
{
    position: absolute;
    /* margin-top: 200px; */
    bottom: 120px;
    display: inline-block;
    padding: 10px 32px;
    border-radius: 20px;
    background: #fff;
    color: #0066FF;
    z-index: 20;
    font-weight: 900;
    box-shadow: 2px 5px 20px 1px #0066FF;
}
.buyfish .buybtn a
{
    text-decoration: none;
    font-size: 2em;
}
.buyfish .buybtn a:hover
{
    color: #0066FF;
}
.buyfish .buybtn a .buybtndiv
{
    width: 100%;
    display: flex;
}
.buyfish .buybtn a .buybtndiv h3 img
{
    height: 33px;
    width: auto;
}
.col-20
{
    width: 30%;
    margin: 0%;
    padding: 0%;
    align-items: center;
    justify-content: center;

}
.col-20 img
{
    margin-top: 14px;
}
.col-80
{
    width: 80%;
    margin: 0%;
    margin-left: 65px;
    margin-right: 20px;
    margin-top: 5px;
    padding: 0%;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}
.col-80 h4{
    white-space:nowrap;
    font-weight: 900;
    font-size: 0.9em;
    margin-bottom: 0%;
    background: linear-gradient(225deg, rgba(20,241,149,1) 0%, rgba(153,69,255,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.col-80 h5{
    font-weight: 900;
    font-size: 0.5em;
    background: linear-gradient(225deg, rgba(20,241,149,1) 0%, rgba(153,69,255,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.buyfish .connectbtn
{
    position: absolute;
    /* margin-top: 200px; */
    bottom: 120px;
    display: inline-block;
    padding: 18px 70px;
    border: solid #fff 3px;
    border-radius: 20px;
    background: #0066FF;
    color: #FFF;
    z-index: 20;
    font-weight: 900;
    box-shadow: 2px 5px 20px 1px #0066FF;
}
.connectbtn a
{
    text-decoration: none;
}
.connectbtn .connectbtndiv
{
    color: #FFF;
}
.connectbtndiv h1
{
    font-size: 1.9em;
    font-family: 'Slackey';
    font-weight: 900;
}
.marketbtn
{
    position: absolute;
    width: 100%;
    height: 120px;
    bottom: 0px;
    /* left: 100%; */
}
.marketbtndiv
{
    position: relative;
    display: flex;
    height: 100%;
    margin: 0;
    padding: 0;
    float: right;
    margin-right: 10vw;
}
.marketbtndiv div
{
    width: 180px;
    height: 100%;
    padding: 0;
}
@keyframes g-slider {
    0% {
        transform: translate(100%, 30px);
    }
    100% {
        transform: translate(0%, 30px);
    }
}
@keyframes g-slider2 {
    0% {
        transform: translate(0%, 30px);
    }
    100% {
        transform: translate(-100%, 30px);
    }
}