.ldescription
{
    position: relative;
    /* min-height: 100vh; */
    background: #18325a;
    justify-content: center;
    align-items: center;
    background-image: url('../img/backgroundstream.png');
    background-repeat: no-repeat; 
    background-position: center;
    background-size: cover;
}
.ldescription .row
{
    justify-content: center;
    align-items: center;
}
.ldescription .panel
{
    margin-top: 80px;
    padding: 70px;
    border-radius: 43px;
    background-color: RGBA(20, 21, 26, 0.8);
}
.ldescription h1
{
    margin-left: -0.4em;
    text-transform: uppercase;
    font-size: 2.8em;
    font-weight: 900;
    color: #fff;
    margin-bottom: 30px;
}
.ldescription h1 span
{
    font-family: 'Slackey';
    background: linear-gradient(to top, #0066FF 50%, transparent 50%);
}
.ldescription p
{
    color: #fff;
    font-size: 2em;
    font-weight: 700;
}
.ldescription .panel .logo
{
    margin-top: 30px;
    /* margin-bottom: -20px; */
}
.ldescription .panel .logo img
{
    height: 1.2em;
    width: auto;
    opacity: 0.22;
}
.ldescription .jellyfish
{
    position: absolute;
    top: 0;
    right: 46px;
}
.ldescription .jellyfish img
{
    width: 90%;
}
@media (max-width: 992px)
{
    .ldescription .jellyfish
    {
        position: absolute;
        top: 0;
        /* right: -32vw; */
    }
    .ldescription .jellyfish img
    {
        width: 50%;
    }
}
@media (max-width: 767px)
{
    .ldescription h1
    {
        font-size: 2em;
    }
    .ldescription p
    {
        font-size: 1em;
    }
}
@media (max-width: 415px)
{
    .ldescription h1
    {
        font-size: 1.2em;
    }
    .ldescription p
    {
        font-size: 0.7em;
    }
}
