.rarity
{
    position: relative;
    min-height: 100vh;
    background: #18325a;
    justify-content: center;
    align-items: center;
    /* background-image: url('../img/backgroundcorn.png');
    background-repeat: no-repeat; 
    background-position: center; */
}
.rarity .topbumper
{
    width: 100%;
    height: 18vh;
}
.rarity .panel
{
    width: 100%;
    padding: 60px;
    border-radius: 32px;
    background-color: rgba(0,0,0,0.74);
}
.rarity .panel .title
{
    margin-top: 80px;
    color: #fff;
    text-transform: uppercase;
}
.rarity .panel .title img
{
    position: absolute;
    width: 200px;
    height: auto;
    left: 50%;
    transform: translate(-50%, -85%);
    border-radius: 50%;
    z-index: 0;
}
.rarity .panel .title h1
{
    font-family: 'Slackey';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 4em;
    z-index: 99;
    font-weight: 900;
}
.rarity .titlebumper
{
    height: 90px;
}
.rarity .raritylist
{
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
}
.rarity .raritylist h4
{
    color: #fff;
    font-size: 2em;
    text-transform: uppercase;
    font-weight: 900;
}
.rarity .raritylist h5
{
    color: #0066FF;
    font-size: 2em;
    font-weight: 900;
}

@media (max-width: 768px)
{
    .rarity .panel .title
    {
        margin-top: 40px;
    }
    .rarity .titlebumper
    {
        height: 45px;
    }
    .rarity .panel .title img
    {
        width: 105px;
    }
    .rarity .panel .title h1
    {
        font-size: 2em;
    }
    .rarity .panel
    {
        padding: 30px;
    }
    .rarity .raritylist h4
    {
        font-size: 1.3em;
    }
    .rarity .raritylist h5
    {
        font-size: 1.3em;
    }
}