.solana-wallet-select-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
}
  
header
{
    position: fixed;
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    padding: 25px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10000;
}
.toggle
{
    display: none;
}
header .logo
{
    /* color: #fff;
    font-weight: 900;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 2em;
    letter-spacing: 2px;
    font-style: italic; */

    height: 2rem;
    width: auto;
    max-width: 50vw;
}
header .logo img
{
    /* color: #fff;
    font-weight: 900;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 2em;
    letter-spacing: 2px;
    font-style: italic; */
    color: #fff;
    padding: 0;
    margin: 0;
    margin-top: -20px;
    height: 100%;
    width: 100%;
}
header ul
{
    display: flex;
    justify-content: center;
    align-items: center;
}
header ul li
{
    list-style: none;
    margin-left: 20px;
}
header ul li a
{
    text-decoration: none;
    padding: 6px 15px;
    font-weight: 600;
    color: #fff;
    border-radius: 20px;
    font-size: 1em;
}
header ul li a:hover,
header ul li a.active
{
    background: #fff;
    color: #000;
    border-radius: 20px;
}
#connect
{
    margin: 0px 0px;
    background: #0066FF;
    color: #fff;
    border-radius: 20px;
    border: solid 1px #fff;
    display: flex;
    font-size: 0.8em;
}
#connect span
{
    font-family: 'Slackey';
}
#connect:hover
{
    background: #fff;
    color: #0066FF;
    border: solid 1px #0066FF;
}
#connect:active
{
    background: #18325a;
    color: #fff;
    border: solid 1px #fff;
}
#account
{
    font-family: 'Slackey';
    margin: 0px 0px;
    background: linear-gradient(to bottom, #00dcff, #00b8ff);;
    color: #fff;
    border-radius: 20px;
    border: solid 1px #fff;
    display: flex;
    font-size: 0.8em;
}
#account i
{
    margin-top: 3px;
    margin-right: 5px;
}
#account span
{
    margin: 2px 0px;
    font-weight: 100;
    font-size: 0.8em;
    width: 60px;
    font-family: 'Slackey';
    overflow: hidden;
    text-overflow: ellipsis;
}
.fade.modal.show
{
    z-index: 20000;
}
.modal-body
{
    max-height: 60vh;
    padding: auto;
    border-radius: 15px;
}
.modal-content
{
    border-radius: 20px;
}

.logincontainer
{
    padding: 20px;
}
.loginbanner
{
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    min-height: 15vh;
    border-radius: 20px;
}
.loginbanner:hover
{
    background-color: #f1f1f1;
}
.loginbanner a
{
    display:inline-block;
    width: 100%;
    height: 100%;
    padding: 0px;
    padding-top: 20px;
    text-decoration: none;
    color: #444;
}
.loginbanner img
{
    width: 2rem;
    height: auto;
}
@media (max-width: 1350px)
{
    header ul li a
    {
        font-size: 0.8em;
    }
}
@media (max-width: 1199px)
{
    header ul li a
    {
        font-size: 0.5em;
    }
}
@media (max-width: 992px){
    header
    {
        padding: 20px;
    }
    .nav
    {
        padding-left: 20px;
    }
    header .navigation
    {
        display: none;
    }
    header .navigation.active
    {
        position: fixed;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 80vw;
        height: 100vh;
        background-color: #0066FF;
    }
    header .navigation li
    {
        margin-left: 0;
    }
    header .navigation li a
    {
        font-size: 1.2em;
        margin: 5px 0;
        display: inline-block;
    }
    header ul
    {
        padding-left: 0;
    }
    header ul li
    {
        list-style: none;
        margin-left: 0px;
        width: 100%;
        text-align: center;
        justify-content: center;
    }
    header ul li a
    {
        width: 100%;
        text-decoration: none;
        padding: 6px 0px;
        font-weight: 600;
        color: #fff;
        border-radius: 20px;
    }
    header ul li a:hover,
    header ul li a.active
    {
        background: #fff;
        color: #000;
        border-radius: 0px;
    }
    header .logo img
    {
        margin: 0;
    }
    .toggle
    {
        display: block;
        position: relative;
        width: 30px;
        height: 30px;
        font-size: 1.3em;
        text-align: center;
        color: #fff;
        cursor: pointer;
        z-index: 100000;
    }
    header ul li a
    {
        font-size: 1em;
    }
    #account
    {        
        width: 150px;
        min-width: 100px;
        position: absolute;
        bottom: 10%;
        left: 50%;
        justify-content: center;
        max-width: 30%;        
        transform:translateX(-50%)
    }
    #account span
    {
        width: 100px;
    }
    #connect
    {
        width: 150px;
        min-width: 100px;
        position: absolute;
        bottom: 10%;
        left: 50%;
        justify-content: center;
        max-width: 30%;        
        transform:translateX(-50%)
    }
}