

.community
{
    position: relative;
    min-height: 100vh;
    background: #18325a;
    justify-content: center;
    align-items: center;
    /* background-image: url('../img/backgroundtrain.png');
    background-repeat: no-repeat; 
    background-position: center; */
    overflow-x: hidden;
}
.community .panelback
{
    position: absolute;
    top: 180px;
    left: 52%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.63);
    width: 580px;
    height: 600px;
    border-radius: 50px;
}

.community .panel
{
    position: absolute;
    top: 146px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    width: 566px;
    height: 600px;
    border-radius: 35px;
    justify-content: center;
    align-items: center;
}

.community .panel .container
{
    max-width: 90vw;
    
}
.community .title
{
    margin-top: 70px;
    margin-bottom: 35px;
    font-weight: bold;
}
.community .title h1
{
    font-family: 'Slackey';
    font-weight: 1000;
    font-size: 3.5em;
}
.communitybtn
{
    background-color: #000;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    padding: 15px;
    border-radius: 32px;
}
.communitybtn img
{
    height: 2em;
    width: auto;
    margin-left: 20px;
}
.communitybtn h5
{
    position: absolute;
    left: 50%;
    transform: translate(-42%, .2em);
    font-size: 1.3em;
    font-weight: 900;
    font-style: italic;
}
#me1
{
    font-size: 1.2em;
}
#me1 span
{
    background: linear-gradient(to right, #9800ff, #ff529f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.communitybtn h4
{
    margin-left: 20px;
    font-size: 1.3em;
    transform: translateY(.2em);
}
.communitybtn .os h5
{
    transform: translate(-50%, .2em);
}
#cmtbtn0
{
    background-color: #000000;
}
#cmtbtn1
{
    background-color: #2081E2;
}
#cmtbtn2
{
    background-color: #5865F2;
}
#cmtbtn3
{
    background-color: #1DA1F2;
}
@media (max-width: 768px)
{
    .community .title h1
    {
        font-size: 3em;
    }
    #os1
    {
        font-size: 1em;
        transform: translate(-50%, .4em);
    }
    #me1
    {
        font-size: 0.9em;
        transform: translate(-45%, .6em);
    }
    .communitybtn
    {
        padding: 15px 4px;
    }
}

.endBackground img
{
    width: 100vw;
}