.roadmap
{padding-top: 50px;
    position: relative;
    min-height: 100vh;
    background: #18325a;
    /* background-image: url('../img/background1.png');
    background-repeat: no-repeat; 
    background-position: center; */
    justify-content: center;
    align-items: center;
}

.object
{
    position: relative;
    width: 800px;
    height: auto;
    display: block;
    /* margin : 0 auto; */
}
.roadmap .topbumper
{
    min-height: 0px;
}
.roadmap h1
{
    font-family: 'Slackey';
    text-transform: uppercase;
    font-size: 3em;
    font-weight: 900;
    color: #fff;
    margin-bottom: 30px;
}
#title1
{
    background-color: #0064FF;
}
#title2
{
    background-color: #9600FF;
}
#title3
{
    background-color: #FF00CD;
}
.roadmap .title
{
    width: 100%;
    padding: 14px;
    border-radius: 16px;
    /* height: 82px; */
    /* vertical-align: middle; */
    display: flex;
    justify-content: space-between;
}
.roadmap .title h3
{
    margin: 0px;
    text-transform: uppercase;
    font-size: 2.3em;
    font-weight: 900;
    color: #fff;
}
#percentage
{
    text-shadow: 2px 4px 6px #000;
}
.roadmap .panel
{
    /* background-color: rgba(0, 0, 0, 0.6); */
    border-radius: 16px;
    width: 100%;
    /* min-height: 200px; */
    margin-top: 24px;
}
.panel img
{
    width: 100%;
    height: auto;
}
.roadmap .commingsoon
{
    background-color: rgba(0, 0, 0, 0.6);
    background-image: url('../img/trash2.png');
    background-size: cover;
    background-repeat:   no-repeat;
    background-position: center center; 
    background-size: auto 420px;
    border-radius: 16px;
    width: 100%;
    min-height: 530px;
    margin-top: 24px;
    justify-content: center;
    align-items: center;
}
.roadmap .commingsoon h1
{
    padding-top: 160px;
    font-family: 'Slackey';
}

@media (max-width: 992px)
{
    .roadmap .title
    {
        margin-top: 24px;
    }
}