.welcome
{
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 2.5em;
}
.welcome .title
{
    color: #fff;
    width: 100%;
    height: 100%;
    text-transform: uppercase;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 7.5em;
}
.welcome .subTitle
{
    color: #fff;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    position: relative;
    text-transform: none;
    top: 0;
    left: 0;
    margin-top: 1.5em;
}
.welcome .title img
{
    position: relative;
    top: 20px;
    left: 0;
    width: 100vw;
    height: auto;
    z-index: 50;
}
.weed 
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    object-fit: cover;
    pointer-events: none;
}
#weed1
{
    animation: slider 700ms linear infinite;
}
#weed2
{
    animation: slider2 700ms linear infinite;
}
.welcome .title h1
{
    font-family: 'Slackey';
    margin-left: -2vh;
    font-size: 150px;
    font-weight: bolder;
    font-style: italic;
    letter-spacing: -1vh;
    white-space: nowrap;
    text-shadow: 1.2vh 0.01vh 0.8vh #0023e6;
}
.welcome img
{
    position: absolute;
    bottom: 50px;
    max-height: 100%;
    width: auto;
}
.welcome .buybtn
{
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: 0px;
}
.nftbtn
{
    position: absolute;
    bottom: 15px;
    text-decoration: none;
    display: inline-block;
    padding: 8px 30px;
    border-radius: 40px;
    background: #fff;
    color: #0066FF;
    font-size: 1.5em;
    z-index: 900;
    font-weight: 900;
}
.nftbtn:hover
{
    color: #0066FF;
}
.nftbtn:after {
	content: '';
	position: absolute;
	top: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-bottom-color: #ffffff;
	border-top: 0;
	border-left: 0;
	margin-left: -10px;
	margin-top: -20px;
}
@keyframes slider {
    0% {
        transform: translate(0px, 0);
    }
    100% {
        transform: translate(100vw, 0);
    }
}
@keyframes slider2 {
    0% {
        transform: translate(-100vw, 0);
    }
    100% {
        transform: translate(0px, 0);
    }
}
@media (max-width: 1999px)
{
    @keyframes slider {
        0% {
            transform: translate(0px, 0);
        }
        100% {
            transform: translate(1920px, 0);
        }
    }
    @keyframes slider2 {
        0% {
            transform: translate(-1920px, 0);
        }
        100% {
            transform: translate(0px, 0);
        }
    }
}
  