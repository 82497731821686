@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Slackey:300,400,500,600,700,800,900&display=swap');
*
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat';
    letter-spacing: 0.1em;
    scroll-behavior: smooth;
}
.App
{
    min-height: 100vh;
    /* background: linear-gradient(#000000, #18325a); */
    background: #000;
}
body
{
    min-height: 100vh;
    overflow-x: hidden;
}
.pagegap
{
    height: 30vh;
}

