.teams
{
    position: relative;
    min-height: 120vh;
    background: #18325a;
    justify-content: center;
    align-items: center;

    background-image: url('../img/teams/deco.png');
    background-repeat: no-repeat; 
    background-position: center;
}
.paneldiv
{
    margin-top: 200px;
    width: 100%;
    height: auto;
}
.paneldiv img
{
    width: 100%;
    height: auto;
    z-index: 10;
}
.teamsbg
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0;
}
.teamsbg img{
    z-index: 0;
}
@media (max-width: 992px) {
    .paneldiv
    {
        margin-top: 20px;
    }
}